@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');
.MuiRating-icon  >  .MuiSvgIcon-root {
    fill: #faaf00;
}
  .jfooter {
      border-top: 1px solid #D6D6D6;
      margin-top: 24px;
      padding-top: 15px;
  }

  .jfooter p {
      font-weight: normal;
      font-size: 9px;
      line-height: 16px;
      color: #545a77;
  }

  .jlink {
      display: flex;
      gap: 10px;
  }

  .jlink a {
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #131c4c;
  }

  .jrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .jsocial {
      display: flex;
      gap: 10px;
  }

  .jsocial a {
      width: 24px;
      height: 24px;
      margin-bottom: 0;
  }